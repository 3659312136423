import { createLocalVue, mount } from '@vue/test-utils';
import Vuex from 'vuex';
import Additional from './';

import analyticsService from '@/services/analytics';
import mediaService from '@/sparkmembers/services/media';
import extraContentsService from '@/sparkmembers/services/extraContents';

jest.mock('@/store');
jest.mock('@/shared/helpers/toast');
jest.mock('@/router', () => ({
  currentRoute: {
    name: 'MockRoute',
  },
}));

const store = {
  modules: {
    course: {
      namespaced: true,
      state: {
        selectedCourse: {},
      },
    },
    school: {
      namespaced: true,
      state: {
        selectedSchool: {
          id: 1,
        },
      },
    },
  },
};

function mountComponent(media) {
  const localVue = createLocalVue();
  localVue.use(Vuex);

  return mount(Additional, {
    localVue,
    mocks: {
      $t: key => key,
      $store: new Vuex.Store(store),
      $router: {
        push: jest.fn(),
      },
      $route: {
        params: { contentId: 1 },
      },
    },
    directives: {
      'b-modal': {},
    },
    stubs: {
      PageHeader: true,
      RouterLink: true,
      BModal: true,
      BImgLazy: true,
      HsGroup: true,
      HsIcon: true,
      HsInput: true,
      HsButton: true,
      HsButtonCircle: true,
      HsLoadingIcon: true,
      VisibilityCard: true,
      AdditionalReleaseCard: true,
    },
  });
}

describe('Additional', () => {
  beforeEach(() => {
    jest.spyOn(mediaService, 'get').mockImplementation(x => x);
  });

  it('failed to load information', () => {
    jest.spyOn(extraContentsService, 'getById').mockRejectedValue();
    const wrapper = mountComponent();
    expect(wrapper.vm.additional).toStrictEqual({
      id: null,
      title: '',
      downloadable: '',
      available: '',
      schedule: {
        release_after: '',
        release_at: '',
      },
    });
  });

  describe('when load information', () => {
    let wrapper;
    beforeEach(async () => {
      jest.spyOn(extraContentsService, 'getById').mockImplementation(id => ({
        id,
        title: 'A',
        downloadable: false,
        available: true,
        release_after: null,
        release_at: null,
        media: null,
      }));
      wrapper = mountComponent();

      await wrapper.vm.$nextTick();
    });

    it('should render', () => {
      expect(wrapper.vm.additional.id).toBe(1);
    });
  });
});
